<template>
  <div class="dropdown is-hoverable">
    <a class="dropdown-trigger">
      <button
        :disabled="loading"
        :class="{ 'is-loading': loading }"
        class="button is-info is-light is-rounded is-small"
      >
        <span>{{ getDate(date) }}</span>
        <span class="icon">
          <i class="fas fa-angle-down" />
        </span>
      </button>
      <div class="dropdown-menu has-arrow is-left">
        <div class="dropdown-content" v-if="!loading">
          <div v-for="check in checks" :key="check.uuid">
            <router-link
              :to="{
                name: 'autocheck',
                params: { uuid: check.uuid }
              }"
              class="dropdown-item"
              @click.native="$mxp.track('autocheck_tabs_view_previous_check')"
            >
              <span>{{ getDate(check.createdAt) }}</span>
            </router-link>
          </div>
          <span class="dropdown-divider" v-if="checks.length" />
          <a class="dropdown-item" @click="$emit('recheck')">
            <span class="icon is-small has-text-success">
              <i class="fas fa-sync-alt" />
            </span>
            <span>Run new check</span>
          </a>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.utils.ts'
export default {
  name: 'AutoCheckPrevious',
  props: {
    checks: {
      type: Array,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    getDate: value => formatDate(value)
  }
}
</script>
